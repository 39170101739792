/* Google Tag Manager */
(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-TQJ44DP');
/* End Google Tag Manager */

function hasCookieOptOut() {
    /* OnetrustActiveGroups.includes("C0002") && */
    return !((OnetrustActiveGroups.indexOf("2") == 0, OnetrustActiveGroups.indexOf(",2") >= 0));
}

document.addEventListener("DOMContentLoaded", function() {
    window.iOneTrust = setInterval(init_cookie , 500);
});

function init_cookie()
{
    //console.log("tick");
    if (typeof(OneTrust) === "undefined" || typeof(OnetrustActiveGroups) === "undefined" ) { return; } else { clearInterval(window.iOneTrust); }
    //console.log(OnetrustActiveGroups);

    if (!hasCookieOptOut()) {
        document.getElementsByTagName('body')[0].classList.add("--cookie-all");
    } else {
        document.getElementsByTagName('body')[0].classList.add("--cookie-optout");
    }

    OneTrust.OnConsentChanged(function (e) {
        console.log("OnConsentChanged");
        console.log(e.detail);
        console.log((e.detail.includes("2")));
        if (e.detail.includes("2")) {
            window.top.document.getElementsByTagName('body')[0].classList.remove("--cookie-optout");
            window.top.document.getElementsByTagName('body')[0].classList.add("--cookie-all");
        } else {
            window.top.document.getElementsByTagName('body')[0].classList.add("--cookie-optout");
            window.top.document.getElementsByTagName('body')[0].classList.remove("--cookie-all");
        }
    });
}

function onDecline(e) {
    document.getElementsByTagName('body')[0].classList.add("--cookie-optout");
    document.getElementsByTagName('body')[0].classList.remove("--cookie-all");
}

function OptanonWrapper() {
  if(!hasCookieOptOut()){
    document.getElementsByTagName('body')[0].classList.add("--cookie-all");
    
  }
}
